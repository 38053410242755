import $ from 'jquery';
import './scss/base.scss';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/popover';

import 'pickadate/lib/picker';
import 'pickadate/lib/picker.date';
import 'pickadate/lib/picker.time';

import Fingerprint2 from 'fingerprintjs2';

import * as popper from 'popper.js';
import * as Clipboard from 'clipboard';

import { showModalError } from './modals';

// Make various things available as variables on window
window.Popper = popper;

document.addEventListener('DOMContentLoaded', () => {
  $('[data-toggle="tooltip"]').tooltip();
});

if (module.hot) {
  module.hot.accept();
}

$(document).ready(() => {
  $('.js-datepicker').pickadate({
    format: 'yyyy-mm-dd',
    hiddenName: true,
  });
  $('.js-timepicker').pickatime({
    format: 'HH:i',
    hiddenName: true,
  });

  $('[data-toggle="popover"]').popover();

  $("input[name='booking_expiry_options']").change(function() { // eslint-disable-line
    if ($(this).val() === 'CANCEL_ON_EXPIRY' || $(this).val() === 'SOFT_EXPIRY') {
      $('.expiry_date_section').show();
      $('#expiry_date').prop('required', true);
    } else {
      $('.expiry_date_section').hide();
      $('#expiry_date').prop('required', false);
    }
  });
});

window.displayProblemRow = () => {
  $('#problemRow').show();
};

/**
 * Clipboard integration
 */
function setTooltip(btn, message) {
  $(btn).tooltip('hide')
    .attr('data-original-title', message)
    .tooltip('show');
}

function hideTooltip(btn) {
  setTimeout(function() { // eslint-disable-line
    $(btn).tooltip('hide');
  }, 2000);
}

window.setTooltip = setTooltip;
window.hideTooltip = hideTooltip;

$(document).ready(() => {
  $('#clipboard').tooltip({
    trigger: 'click',
    placement: 'bottom',
  });

  if (document.getElementById('clipboard')) {
    const clipboardBtn = document.getElementById('clipboard');
    const clipboard = new Clipboard(clipboardBtn);
    clipboard.on('success', function(e) { // eslint-disable-line
      setTooltip(e.trigger, 'Copied!');
      hideTooltip(e.trigger);
    });
  }
});


/**
 * Refund form listeners
 */
$(document).ready(() => {
  // show and hide refund form
  $('button.js-request-refund-button').click(function () { // eslint-disable-line
    $('.js-request-refund-button-container').hide();
    $('.js-request-refund-form').show();
  });

  $('button.js-edit-button').click(function () { // eslint-disable-line
    $('div.js-controls-section').hide();
    $('div.js-table-section').hide();
    $('div.js-edit-form-section').show();
  });

  $('button.js-cancel-button').click(function () { // eslint-disable-line
    if (confirm('Are you sure you want to cancel?')) { // eslint-disable-line
      $('div.js-controls-section').show();
      $('div.js-table-section').show();
      $('div.js-edit-form-section').hide();
    }
  });

  // show and hide reason/amount field
  const $refundAmountContainer = $('div.js-refund-amount-container');
  const $amountInput = $('input[name=amount]');
  $('input[name="full_or_partial"]').click(function () { // eslint-disable-line
    if ($(this).val() === 'partial') {
      $refundAmountContainer.show();
      $amountInput.prop('required', true);
    } else {
      $refundAmountContainer.hide();
      $amountInput.prop('required', false);
    }
  });
});

// Browser fingerprinting for aiding in fraud prevention
const recordTimeout = () => {
  if (window.doFingerprint) {
    Fingerprint2.getV18((result, components) => {
      components.push({ key: 'default', value: result }); // Include the fingerprint hash
      $.ajax({
        type: 'POST',
        url: '/fp',
        contentType: 'application/json',
        data: JSON.stringify({
          data: components,
        }),
      });
    });
  }
};

setTimeout(recordTimeout, 50); // 50ms wait recommended by fingerprint library

/**
 * Checkin button listeners
 */
$(document).ready(() => {
  $('.ajax-check-in-btn').click((ev) => {
    const bookingId = $(ev.target).attr('data-booking-id');
    const url = `/m/bookings/ajax/checkin/${bookingId}`;
    const originalText = $(ev.target).text();
    $(ev.target).text('Checking in...');
    $.post(
      url,
      (data) => {
        const { success, error } = data;
        if (success) {
          let html = '';
          const checkInDate = new Date().toISOString().slice(0, 10);
          html += '<span class="status-icon bg-success"></span>Checked-in<br>';
          html += `<span class="status-icon bg-success invisible"></span><span class="text-muted">${checkInDate}</span>`;
          $(ev.target).parent().html(html);
        } else {
          $(ev.target).text(originalText);
          showModalError(`Check-in failed: ${error}`);
        }
      },
    ).fail(() => {
      $(ev.target).text(originalText);
      showModalError('Check-in failed. Please try again later.');
    });
  });
});


/**
 * Contract form listeners
 */
$(document).ready(() => {
  $('form.js-sign-contract').off('submit');

  $('form.js-sign-contract').submit(() => {
    if (!$('input[type=checkbox]')[0].checked) {
      alert('You must check the box above to agree to the terms.'); // eslint-disable-line
      return false;
    }
    return true;
  });
});


/**
 * Login page caps lock listener
 */
$(document).ready(() => {
  // check we are on the login page
  const alert = $('div.js-capslock-warning');
  if (alert) {
    $('#password').keyup((ev) => {
      const e = ev.originalEvent;
      if (e instanceof window.KeyboardEvent) {
        if (e.getModifierState('CapsLock')) {
          alert.show();
        } else {
          alert.hide();
        }
      }
    });
  }
});
